<button class="btn btn-primary position-relative" [disabled]="isBusy" (click)="resetClaimStatus()">
    <span
      class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto"
      role="status"
      aria-hidden="true"
      [ngClass]="{'show': isBusy}">
    </span>
    <span class="text fade show" [ngClass]="{'show': !isBusy}">
      Reset Claim Status
    </span>
</button>