<hr class="mb-1 mt-0 text-black">
<div class="mb-4">
    <span class="h4">Related Documents</span>
    <button class="btn btn-link btn-collapse float-end p-0" type="button" data-bs-toggle="collapse" data-bs-target="#documentsExpandCollapse" aria-expanded="true" aria-controls="attachmentsExpandCollapse">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg>
    </button>
</div>
<div id="documentsExpandCollapse" class="collapse show mb-4">
    <app-spinner [show]="isLoading" [center]="true"></app-spinner>
    <ng-container *ngIf="!isLoading">
        <p *ngIf="documents.length === 0" class="pb-2">
            There are no warranty or customer documents related to this claim.
        </p>
        <div *ngIf="documents.length > 0" class="table-responsive">
            <table class="table table-sm table-striped table-hover lh-md">
                <thead>
                    <tr>
                        <th></th>
                        <th class="w-auto">Filename</th>
                        <th>File Type</th>
                        <th class="text-end pe-3">Size</th>
                        <th>Document Type</th>
                        <th class="w-auto">Description</th>
                        <th>Created Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="document-list" *ngFor="let document of documents" role="button" (mouseover)="openTooltip(tooltip)" (mouseout)="closeTooltip(tooltip)" (click)="viewDocument(document)">
                        <td class="py-0" [innerHtml]="document.fileIcon"></td>
                        <td class="w-auto">{{document.fileName}}</td>
                        <td>{{document.fileType.toUpperCase()}}</td>
                        <td class="text-end pe-3">{{Utils.formatBytes(document.fileSize, 0)}}</td>
                        <td>{{document.documentType == null ? '' : document.documentType + ' Document' }}</td>
                        <td class="w-auto">{{document.description}}</td>
                        <td>{{Utils.formatDate(document.createdDate)}} {{Utils.formatTime(document.createdDate)}}</td>
                        <td class="py-0 text-end">
                            <button #tooltip="ngbTooltip" class="btn btn-link p-0" ngbTooltip="View">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>
