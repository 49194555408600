<div class="modal-header">
    <h4 class="modal-title ms-3">Confirm Global Limits</h4>
    <button type="button" class="btn-close me-3" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
</div>

<div class="modal-body py-4">
    <div class="container-fluid px-3">
        <div class="alert alert-danger alert-outline-coloured mb-3" *ngIf="errorSaving">
            <div class="alert-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                    <line x1="12" y1="8" x2="12" y2="12"></line>
                    <line x1="12" y1="16" x2="12.01" y2="16"></line>
                </svg>
            </div>
            <div class="alert-message">
                <strong>An error occurred.</strong> Unable to save global limits.
            </div>
        </div>
        <div class="mb-3">
            Do you wish to save the following global limits?
        </div>
        <div class="bg-light rounded-3 px-3 py-2">
            <table class="table table-sm table-no-bottom-border lh-md mb-0">
                <tbody>
                <tr>
                    <th class="text-end text-nowrap w-50">Max Amount Limit</th>
                    <td class="w-50">{{maxAmountLimit|currency:'CAD':'symbol-narrow':'1.0'}}</td>
                </tr>
                <tr>
                    <th class="text-end text-nowrap w-50">Max Percentage Limit</th>
                    <td class="w-50">{{maxPercentageLimit}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="px-3">
        <button type="button" class="btn btn-secondary me-3" (click)="closeModal()">Cancel</button>
        <button
            class="btn btn-primary position-relative"
            type="button"
            (click)="saveGlobalLimits()"
            [disabled]="busySaving"
        >
            <span
                class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto"
                role="status"
                aria-hidden="true"
                [ngClass]="{'show': busySaving}">
            </span>
            <span class="text fade show" [ngClass]="{'show': !busySaving}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save me-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
                Save
            </span>
        </button>
    </div>
</div>
