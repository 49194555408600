<div class="btn-group w-100 mt-3 d-flex" role="group">
  <button
    class="btn btn-lg btn-success lh-md flex-grow-1 px-3"
    ngbTooltip="Accept Claim"
    [ngClass]="{'text-nowrap': !minimalUi}"
    [disabled]="actionDisabled"
    type="button"
    (click)="approve()"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
         class="feather feather-check me-0 me-xxl-2">
      <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
    <span [ngClass]="{'d-block': minimalUi}">Accept Claim</span>
  </button>
  <button
    class="btn btn-lg btn-danger lh-md flex-grow-1 px-3"
    ngbTooltip="Reject Claim"
    [ngClass]="{'text-nowrap': !minimalUi}"
    [disabled]="actionDisabled"
    type="button"
    (click)="reject()"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
         class="feather feather-x me-0 me-xxl-2">
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
    <span [ngClass]="{'d-block': minimalUi}">Reject Claim</span>
  </button>
</div>
