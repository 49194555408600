import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ApproveService {
    constructor(private httpClient: HttpClient) {
    }

    approve(claimId: number, adjusterFinalMessage?: string) {
        return this.httpClient.put(`${environment.apiServer}/Adjuster/Claim/Approve`, {claimId, adjusterFinalMessage});
    }
}
