<fieldset>
  <label class="form-label text-nowrap mb-1 lh-sm">Deductible</label>
  <div *ngIf="minimalUi" class="input-group">
    <span class="input-group-text">$</span>
    <input
      class="form-control"
      type="number"
      [readOnly]="claimDto!.isLocked"
    >
  </div>
  <label *ngIf="!minimalUi" class="form-check mb-0">
    <input
      class="form-check-input"
      type="radio"
      name="override-deductible"
      (change)="setManuallyEnteredDeductible()"
      [(ngModel)]="claimDto.manuallyEnteredDeductible"
      [value]="null"
      [readOnly]="claimDto!.isLocked"
    >
    Keep at {{claimDto.warranty.deductible | currency}}
  </label>
  <label *ngIf="!minimalUi" class="form-check mb-0">
    <input
      class="form-check-input"
      type="radio"
      name="override-deductible"
      (change)="setManuallyEnteredDeductible()"
      [(ngModel)]="claimDto.manuallyEnteredDeductible"
      [value]="0"
      [readOnly]="claimDto!.isLocked"
    >
    Waive deductible ($0)
  </label>
  <label *ngIf="!minimalUi" class="form-check mb-3">
    <span class="d-inline-block mb-1">Modify deductible:</span>
    <div class="input-group">
      <span class="input-group-text">$</span>
      <input
        [(ngModel)]="claimDto.manuallyEnteredDeductible"
        (change)="debounceUpdateManuallyEnteredDeductible()"
        (keyup)="debounceUpdateManuallyEnteredDeductible()"
        class="form-control"
        type="number"
        [readOnly]="claimDto!.isLocked"
      >
    </div>
  </label>
</fieldset>
