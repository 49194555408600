import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NgIf, NgClass, CurrencyPipe} from "@angular/common";
import {SetGlobalConfigurationService} from "../../services/set-global-configuration.service";

@Component({
  selector: 'app-confirm-global-configuration-modal',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    CurrencyPipe
  ],
  templateUrl: './confirm-global-configuration-modal.component.html',
  styleUrl: './confirm-global-configuration-modal.component.scss'
})
export class ConfirmGlobalConfigurationModalComponent {
  @Input() public maxAmountLimit!: number;
  @Input() public maxPercentageLimit!: number;
  public busySaving: boolean = false;
  public errorSaving: boolean = false;

  constructor(
      private activeModal: NgbActiveModal,
      private setGlobalConfigurationService: SetGlobalConfigurationService,
  ) {
  }

  public closeModal(): void {
    this.activeModal.close();
  }
      
  public saveGlobalLimits(): void {
    this.busySaving = true;
    this.errorSaving = false;
    this.setGlobalConfigurationService.setGlobalConfiguration(this.maxAmountLimit, this.maxPercentageLimit).subscribe({
      next: () => {
        this.busySaving = false;
        this.closeModal();
      },
      error: () => {
        this.busySaving = false;
        this.errorSaving = true;
      }
    });
  }

}
