<hr class="mb-1 mt-0 text-black">
<div class="mb-4">
  <span class="h4">Attachments</span>
  <button class="btn btn-link btn-collapse float-end p-0" type="button" data-bs-toggle="collapse" data-bs-target="#attachmentsExpandCollapse" aria-expanded="true" aria-controls="attachmentsExpandCollapse">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg>
  </button>
</div>
<div id="attachmentsExpandCollapse" class="collapse show mb-4">
  <div class="row">
    <div class="col-md-8 mb-3">
      <div class="d-flex dropzone rounded p-0 h-100" appDnd (fileDropped)="onFileDropped($event)">
        <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" />
        <div class="text-center mx-auto my-3 align-self-center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.6 40" fill="currentColor">
            <path d="M29.2,5.2c-0.3-0.3-0.6-0.6-0.9-0.9C28,4,27.7,3.7,27.4,3.4C25.3,1.2,24.1,0,22.8,0h-9.8C10.2,0,8,2.2,8,4.9V8H4.9
                                                                      C2.2,8,0,10.2,0,12.9v22.2C0,37.8,2.2,40,4.9,40h14.8c2.7,0,4.9-2.2,4.9-4.9V32h3.1c2.7,0,4.9-2.2,4.9-4.9V9.8
                                                                      C32.6,8.5,31.4,7.3,29.2,5.2z M22.2,35.1c0,1.4-1.1,2.5-2.5,2.5H4.9c-1.4,0-2.5-1.1-2.5-2.5V12.9c0-1.4,1.1-2.5,2.5-2.5h9
                                                                      c0.9,0.2,0.9,1.3,0.9,2.4v3.8c0,0.7,0.6,1.2,1.2,1.2h3.7c1.2,0,2.5,0,2.5,1.2L22.2,35.1z M30.2,27.1c0,1.4-1.1,2.5-2.5,2.5h-3.1
                                                                      V17.8c0-1.3-1.2-2.6-3.4-4.7c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.3-0.6-0.6-0.9-0.9C17.3,9.2,16.1,8,14.8,8h-4.3V4.9
                                                                      c0-1.4,1.1-2.5,2.5-2.5h9c0.9,0.2,0.9,1.3,0.9,2.4v3.8c0,0.7,0.6,1.2,1.2,1.2h3.7c1.2,0,2.5,0,2.5,1.2L30.2,27.1z"/>
          </svg>
          <span class="d-block lh-md mt-2">Drag and drop files here... <br><small>25 MB max</small></span>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-3">
      <p><strong>{{IsAdjusterView ? 'Please attach any supporting documents necessary for this claim.' : 'Please attach any supporting documents to complete your claim.'}}</strong> Be sure to include pictures of defective parts, invoices, sublets, estimates of repairs (parts/labour), etc.</p>
      <button class="btn btn-outline-primary" type="button" (click)="fileDropRef.click();">Select Files...</button>
    </div>
  </div>
  <div class="mb-4">
    <p class="pb-2" *ngIf="!files.length">No attachments.</p>
    <ul class="attachment-list" *ngIf="files.length">
      <li class="tag-file cursor-pointer" *ngFor="let file of files" (click)="viewDocument(file)">
        <span [ngStyle]="{'width': file.progress + '%'}"></span>
        <div [innerHtml]="file.icon"></div>
        <div>
          <strong>{{file.name}}</strong>
          <small>{{Utils.formatBytes(file.size)}}</small>
        </div>
        <button
          type="button"
          class="btn-close"
          [ngClass]="{'d-none': file.convertingFile}"
          (click)="deleteUploadedFile(file);$event.stopPropagation()">
        </button>
        <div
          class="spinner spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          [ngClass]="{'d-none': !file.convertingFile}">
        </div>
      </li>
    </ul>
    <p *ngIf="uploadError">An error occurred while uploading the file. Please contact us at 1-800-555-1212.</p>
  </div>
</div>
