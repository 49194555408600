import {Component, Input, OnInit} from '@angular/core';
import {UpdateTowingService} from "./services/update-towing.service";
import {debounceTime, distinctUntilChanged, Subject, switchMap} from "rxjs";

@Component({
  selector: 'app-towing',
  templateUrl: './towing.component.html',
  styleUrls: ['./towing.component.scss']
})
export class TowingComponent implements OnInit {
  @Input() claimId!: number;
  @Input() towing!: number | null;
  @Input() isLocked: boolean = false;

  towingUpdate$ = new Subject<number | null>();
  error = false;

  constructor(private updateTowingService: UpdateTowingService) {
  }

  updateTowing() {
    this.towingUpdate$.next(this.towing);
  }

  ngOnInit(): void {
    this.towingUpdate$.next(this.towing);
    this.towingUpdate$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(towing => this.updateTowingService.updateTowing(this.claimId, towing)),
    ).subscribe(
      {
        error: error => {
          this.error = true;
        }
      });
  }

}
