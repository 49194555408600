<div class="d-inline-block">
  <div class="text-center">
    <label class="d-block form-label text-uppercase lh-sm mb-1">Claim Status</label>
    <div
      [ngClass]="minimalUi ? 'fs-4 py-2' : 'fs-2'"
      class="badge {{badgeBackgroundClass}} fw-bold text-uppercase border border-white shadow-lg px-3"
    >
      {{ statusText }}
    </div>
  </div>
</div>