<div class="row mb-3">
  <div class="col-auto">
    <h1 class="h3 mt-3 mb-0">Claims List</h1>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="mb-4">
          <div class="d-inline-block mb-3 me-2">
            <div class="ps-1">
              <small>Status</small>
            </div>
            <angular2-multiselect
              [data]="claimStatusMultiselect"
              [(ngModel)]="claimStatusSelectedItems"
              [settings]="claimStatusMultiselectSettings"
              (onClose)="onClaimStatusMultiselectClose()"
            >
            </angular2-multiselect>
          </div>
          <hr class="mt-0" />
        </div>
        <div class="table-responsive" *ngIf="!errorOccurred">
          <table datatable #claimsDataTable [dtOptions]="dtOptions" class="table table-sm table-striped table-hover lh-md w-100"></table>
        </div>
        <p *ngIf="errorOccurred">An error occurred while loading the Claims information.  Click here to retry.  <button (click)="errorOccurred = false;">Retry</button></p>
      </div>
    </div>
  </div>
</div>
