<label class="form-label text-nowrap mb-0">Date of Occurrence <span class="required">*</span></label>
<div class="input-group">
  <input
    class="form-control"
    placeholder="yyyy-mm-dd"
    name="dp"
    [(ngModel)]="dateOfOccurrence"
    [disabled]="isLocked"
    (dateSelect)="updateDateOfOccurrence()"
    [minDate]="{year: minDate.getFullYear(), month: minDate.getMonth() + 1, day: minDate.getDate()}"
    [maxDate]="{year: maxDate.getFullYear(), month: maxDate.getMonth() + 1, day: maxDate.getDate()}"
    ngbDatepicker
    #d="ngbDatepicker"
  />
  <button class="btn btn-outline-primary btn-bg-white bi bi-calendar3" [disabled]="isLocked" (click)="d.toggle()" type="button">Select...</button>
</div>
<div class="invalid-feedback">Please select the occurrence date.</div>
