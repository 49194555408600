<div class="position-fixed sticky-top start-0 bg-light w-100">
    <ul class="text-end shadow-lg">
      <li>
        <span>Repair Total</span>
        <span class="fs-3">{{repairTotal | currency}}</span>
      </li>
      <li *ngIf="claimDto.warranty!.deductible !== null">
        <span>Deductible</span>
        <span class="fs-3">{{claimDto.warranty.deductible | currency}}</span>
      </li>
      <li>
        <span>Subtotal</span>
        <span class="fs-3">{{subTotal | currency}}</span>
      </li>
      <li *ngIf="pst">
        <span>PST</span>
        <span class="fs-3">{{pst | currency}}</span>
      </li>
      <li *ngIf="gst">
        <span>GST</span>
        <span class="fs-3">{{gst | currency}}</span>
      </li>
      <li *ngIf="tax">
        <span>{{claimDto.tax.label}}</span>
        <span class="fs-3">{{tax | currency}}</span>
      </li>
      <li>
        <span class="fw-bold">Total</span>
        <span class="fs-2 text-dark">{{total | currency}}</span>
      </li>
    </ul>
</div>
