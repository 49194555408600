<div class="modal-header">
    <h4 class="modal-title ms-3">{{modalTitle}}</h4>
    <button type="button" class="btn-close me-3" data-bs-dismiss="modal" aria-label="Close" (click)="closeClaimLimitsModal()"></button>
</div>

<div class="modal-body pt-4 pb-5">
    <div class="container-fluid px-3">
        <div class="alert alert-danger alert-outline-coloured mb-4" *ngIf="errorSaving">
            <div class="alert-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                    <line x1="12" y1="8" x2="12" y2="12"></line>
                    <line x1="12" y1="16" x2="12.01" y2="16"></line>
                </svg>
            </div>
            <div class="alert-message">
                <strong>An error occurred.</strong> Unable to save limits for this {{friendlyTextType}}.
            </div>
        </div>
        <div class="bg-light rounded-3 px-3 py-2 mb-4">
            <table class="table table-sm table-no-bottom-border lh-md mb-0">
                <tbody *ngIf="vendor">
                    <tr>
                        <th class="text-end text-nowrap">Name</th>
                        <td class="w-65">{{vendor.friendlyName !== '' ? vendor.friendlyName : vendor.legalName}}</td>
                    </tr>
                    <tr>
                        <th class="text-end text-nowrap">ID</th>
                        <td class="w-65">{{vendor.vendorId}}</td>
                    </tr>
                    <tr>
                        <th class="text-end">Address</th>
                        <td class="w-65">
                            {{vendor.address}}<br>
                            {{vendor.city}}, {{vendor.province}} {{Utils.formatPostalZipCode(vendor.postalZipCode)}}
                        </td>
                    </tr>
                    <tr>
                        <th class="text-end text-nowrap">Phone</th>
                        <td class="w-65">{{Utils.formatPhoneNumber(vendor.phone)}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="repairCentre">
                <tr>
                    <th class="text-end text-nowrap">Name</th>
                    <td class="w-65">{{repairCentre.friendlyName !== '' ? repairCentre.friendlyName : repairCentre.name}}</td>
                </tr>
                <tr>
                    <th class="text-end text-nowrap">ID</th>
                    <td class="w-65">{{repairCentre.repairCentreId}}</td>
                </tr>
                <tr>
                    <th class="text-end">Address</th>
                    <td class="w-65">
                        {{repairCentre.address}}<br>
                        {{repairCentre.city}}, {{repairCentre.province}} {{Utils.formatPostalZipCode(repairCentre.postalCode)}}
                    </td>
                </tr>
                <tr>
                    <th class="text-end text-nowrap">Phone</th>
                    <td class="w-65">{{Utils.formatPhoneNumber(repairCentre.phone)}}</td>
                </tr>
                </tbody>                
            </table>
        </div>
        <div class="row">
            <fieldset class="col-6">
                <div class="form-label text-nowrap mb-0">Max Amount Limit</div>
                <div class="input-group">
                    <span class="input-group-text">$</span>
                    <input class="form-control" type="number" onkeydown="return event.keyCode !== 69" [(ngModel)]="maxAmountLimit">
                    <button class="btn btn-outline-danger" type="button" ngbTooltip="Remove Limit" (click)="removeAmountLimit()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                    </button>
                </div>
            </fieldset>
            <fieldset class="col-6">
                <div class="form-label text-nowrap mb-0">Max Percentage Limit</div>
                <div class="input-group">
                    <input class="form-control" type="number" onkeydown="return event.keyCode !== 69" [(ngModel)]="maxPercentageLimit">
                    <span class="input-group-text">%</span>
                    <button class="btn btn-outline-danger" type="button" ngbTooltip="Remove Limit" (click)="removePercentageLimit()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                    </button>                    
                </div>
            </fieldset>            
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="px-3">
        <button type="button" class="btn btn-secondary me-3" (click)="closeClaimLimitsModal()">Cancel</button>
        <button 
            class="btn btn-primary position-relative" 
            type="button" 
            (click)="saveClaimsLimit()"
            [disabled]="busySaving"
        >
            <span
                class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto"
                role="status" 
                aria-hidden="true" 
                [ngClass]="{'show': busySaving}">
            </span>
            <span class="text fade show" [ngClass]="{'show': !busySaving}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save me-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
                Save
            </span>
        </button>        
    </div>
</div>
