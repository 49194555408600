<div class="modal-header">
  <h4 class="modal-title  ms-3">Reject Claim</h4>
  <button type="button" class="btn-close me-2" data-bs-dismiss="modal" aria-label="Close" (click)="this.activeModal.close()"></button>
</div>

<ng-container *ngIf="showSuccess">
  <div class="modal-body mx-3">
    <div class="bg-light rounded p-3 mb-3">
      <div class="text-center pt-3">
        <svg class="text-danger" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
        <div class="text-danger fs-4 fw-bolder lh-sm mt-1">REJECTED</div>
        <div class="mt-3 fw-bolder text-dark">Claim {{claimDto.claimId}} has been rejected.</div>
        <p>The repair center has been notified and this claim has been pushed to E2.</p>
        <p>Any further changes will need to be done in E2 and manually communicated to the repair center.</p>
        <a href="https://e2.eazeetrak.com/claims/{{claimDto.e2ClaimId}}/detail" class="btn btn-outline-primary btn-bg-white rounded mb-3" target="_blank">
          <span class="me-2">View Claim in E2</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
        </a>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!showSuccess">
  <div class="modal-body mx-3">
    <div *ngIf="discrepancies.discrepancyFound" class="alert alert-warning alert-outline-coloured">
      <div class="alert-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
      </div>
      <div class="alert-message">
        <strong>Discrepancies were found:</strong>
        <ul class="ps-3 mb-0">
          <li *ngIf="discrepancies.odometer">Current odometer is lower than last known reading</li>
          <li *ngIf="discrepancies.manuallyEnteredDeductible">Deductible has been waived or changed</li>
          <li *ngIf="discrepancies.createdOverMonthOld">Date of occurrence is over a month old</li>
          <li *ngIf="!discrepancies.customerRecordMatch">Customer data does not match what is on file</li>
        </ul>
      </div>
    </div>
    <div *ngIf="!discrepancies.discrepancyFound" class="alert alert-info alert-outline-coloured">
      <div class="alert-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="16" x2="12" y2="12"></line>
          <line x1="12" y1="8" x2="12.01" y2="8"></line>
        </svg>
      </div>
      <div class="alert-message">No discrepancies found.</div>
    </div>
    <div class="row mb-3">
      <div class="col-8 d-flex">
        <div class="flex-fill bg-light rounded px-4 py-3">
          <div class="mb-3"><strong>Repair Work Items</strong></div>
          <hr class="my-0">
          <table class="table table-sm table-no-bottom-border align-top lh-md mb-0">
            <tbody>
            <tr *ngFor="let repair of repairsDto; let i = index">
              <td class="text-end align-top ps-0">{{ i + 1 }}.</td>
              <td class="w-50 align-top">{{repair.partLabel ?? 'Part Not Listed'}}</td>
              <td class="text-nowrap align-top pe-0">P{{ repair.partPrice|currency }} x Q{{repair.partQuantity ?? 1}} + L{{ repair.labourPrice ? (repair.labourPrice|currency) : (0|currency) }} =
                <strong>{{ repair.total|currency }}</strong>
              </td>
            </tr>
            </tbody>
          </table>
          <hr class="my-0">
        </div>
      </div>
      <div class="col-4 d-flex">
        <div class="flex-fill bg-light rounded px-4 py-3">
          <ul class="price-totals">
            <li class="price-item"><span>Repair Total</span><span class="fs-3">{{ repairTotal|currency }}</span></li>
            <li class="price-item"><span>Deductible</span><span class="fs-3">{{ deductible|currency }}</span></li>
            <li class="price-line"></li>
            <li class="price-item"><span>Subtotal</span><span class="fs-3">{{ subTotal | currency }}</span></li>
            <li class="price-spacer"></li>
            <li class="price-item"><span>{{ claimDto.tax!.label }}</span><span class="fs-3">{{ tax|currency }}</span></li>
            <li class="price-line"></li>
            <li class="price-item"><span class="fw-bold text-danger">Total</span><span class="fs-2 text-danger">{{ total|currency }}</span></li>
          </ul>
        </div>
      </div>
    </div>
      <fieldset class="mb-3">
        <label class="form-label mb-0 lh-sm" for="rejectClaimFinalMessage">Final message explaining rejection to repair center</label>
        <textarea 
            [(ngModel)]="finalMessage"
            [ngClass]="{'is-invalid': !isFinalMessageValid}"
            (keyup)="validateFinalMessage()"
            class="form-control" id="rejectClaimFinalMessage"
            name="rejectClaimFinalMessage" type="text" rows="5">
        </textarea>
        <div class="invalid-feedback lh-sm">Please provide a final message for the rejected claim.</div>
      </fieldset>
  </div>
  
  <div class="modal-footer">
    <div class="px-3">
      <button [disabled]="isBusy" class="btn btn-lg btn-outline-secondary me-3" (click)="this.activeModal.close()">Cancel</button>
      <button [disabled]="isBusy" class="btn btn-lg btn-danger position-relative" (click)="rejectClaim()">
        <span
            class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto"
            role="status"
            aria-hidden="true"
            [ngClass]="{'show': isBusy}">
        </span>
        <span class="text fade show" [ngClass]="{'show': !isBusy}">
          <svg 
              xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-x">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
          Reject Claim
        </span>
      </button>
    </div>
  </div>
</ng-container>