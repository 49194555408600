<ng-container *ngIf="!adjustersListLoading && !adjusterLoading && !errorAdjusterList">
  <fieldset>
    <label class="form-label text-nowrap lh-sm mb-0">Adjuster</label>
    <div class="input-group">
      <select class="form-select" [disabled]="claimDto!.isLocked" (change)="assignTo()" [(ngModel)]="adjusterId">
        <option [ngValue]="" selected>[Assign To...]</option>
        <option *ngFor="let adjuster of adjustersList" [ngValue]="adjuster.userId">{{adjuster.name}}</option>
      </select>
      <button class="btn btn-outline-primary btn-bg-white" [disabled]="claimDto!.isLocked" (click)="assignToMe()">Assign to Me</button>
    </div>
  </fieldset>
</ng-container>
<ng-container *ngIf="errorAdjusterList">
  <p class="text-danger">An error occurred while getting the adjuster list.</p>
</ng-container>
<ng-container *ngIf="errorAdjusterClaim">
  <p class="text-danger">An error occurred while getting or setting the adjuster of this claim.</p>
</ng-container>
