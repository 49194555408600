<div *ngIf="claimDto" class="position-fixed sticky-top start-0 w-100 shadow-lg border-bottom d-none d-lg-block visually-hidden">
  <div class="container-fluid bg-primary-dark text-light d-block d-md-flex">
    <app-vehicle-information
      [claim]="claimDto"
      class="d-block d-xl-flex">
    </app-vehicle-information>
    <div class="border-start border-white-35 d-none d-md-flex ps-md-4"></div>
    <app-warranty-information
      [warranty]="claimDto!.warranty"
      [IsAdjusterView]="true"
      class="pt-3 pt-1 pt-md-1">
    </app-warranty-information>
  </div>
  
  <div class="container-fluid bg-stat">
    <div class="row row-cols-auto justify-content-between">
      
      <div class="mt-auto mb-0 border-end border-1 border-gray-500 pe-3 me-2">
        <app-claim-status 
          class="d-inline-block"
          [claimDto]="claimDto!" 
          [minimalUi]="true">
        </app-claim-status>
        <app-claim-authorization-number 
          *ngIf="claimDto!.authorizationNumber" 
          class="d-inline-block ms-4"
          [claimDto]="claimDto!" 
          [minimalUi]="true">
        </app-claim-authorization-number>
      </div>
      
      <app-assign-to [claimDto]="claimDto" class="col-3 mt-auto mb-0"></app-assign-to>
      
      <app-deductible *ngIf="claimDto.warranty?.deductible !== null" [claimDto]="claimDto" [minimalUi]="true" class="col-2 mt-auto mb-0"></app-deductible>
      
      <div class="d-flex ms-auto mt-auto mb-0">
        <div class="flex-grow-1 mt-auto">
          <app-is-claim-test-data [ClaimDto]="claimDto!" class="d-block mb-1"></app-is-claim-test-data>
          <app-update-button
            *ngIf="!claimDto!.isLocked"
            [repairTotal]="repairTotal"
            [isLocked]="claimDto!.isLocked"
            [busySubmitting]="busySubmitting"
            [isAdjusterView]="true"
            (updateButtonClicked)="updateClaim()">
          </app-update-button>
        </div>
        <app-approve-reject 
          *ngIf="!claimDto!.isLocked"
          class="flex-grow-1 mt-auto ms-3"
          [claimDto]="claimDto!" 
          [repairsDto]="repairsDto" 
          [repairTotal]="repairTotal"
          [minimalUi]="true">
        </app-approve-reject>
      </div>
      
    </div>
  </div>
  
  <div class="container-fluid bg-light border-top">
    <ul class="text-end lh-md">
      <li>
        <span>Repair Total</span>
        <span class="fs-3">{{repairTotal | currency}}</span>
      </li>
      <li *ngIf="claimDto.warranty?.deductible !== null">
        <span>Deductible</span>
        <span class="fs-3" *ngIf="claimDto">{{claimDto.manuallyEnteredDeductible ?? claimDto.warranty.deductible | currency}}</span>
      </li>
      <li *ngIf="claimDto.warranty?.deductible !== null">
        <span>Subtotal</span>
        <span class="fs-3">{{subTotal | currency}}</span>
      </li>
      <li *ngIf="pst">
        <span>PST</span>
        <span class="fs-3">{{pst | currency}}</span>
      </li>
      <li *ngIf="pst">
        <span>GST</span>
        <span class="fs-3">{{gst | currency}}</span>
      </li>
      <li *ngIf="tax">
        <span>{{claimDto!.tax.label!}}</span>
        <span class="fs-3">{{tax | currency}}</span>
      </li>
      <li>
        <span class="fw-bold">Total</span>
        <span class="fs-2 text-dark">{{total | currency}}</span>
      </li>
    </ul>
  </div>
</div>
