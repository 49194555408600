<label class="form-label text-nowrap mb-0">Current Odometer <span class="required">*</span></label>
<div class="input-group">
  <input [disabled]="claimDto.isLocked" [readOnly]="claimDto.isLocked" class="form-control" [(ngModel)]="claimDto.odometerReading" (keyup)="debounceOdometerUpdate()" type="number" required>
  <div class="input-group-text pb-0">
    <label class="form-check me-3">
      <input [disabled]="claimDto.isLocked" [readOnly]="claimDto.isLocked" class="form-check-input" type="radio" value="km" name="odometerType"
        [(ngModel)]="claimDto.odometerUnits" (change)="updateOdometer()">
      km
    </label>
    <label class="form-check">
      <input [disabled]="claimDto.isLocked" [readOnly]="claimDto.isLocked" class="form-check-input" type="radio" value="mi" name="odometerType"
        [(ngModel)]="claimDto.odometerUnits" (change)="updateOdometer()">
      mi
    </label>
  </div>
</div>
