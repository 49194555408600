<app-spinner [show]="deleting" [center]="true"></app-spinner>
<ng-container *ngIf="repair && !deleting">
  <div class="row">
    <div class="col-xl-9 px-3 mb-xl-2" [ngClass]="claim?.isSubmitted ? 'mb-xl-2' : 'mb-2'">
      <div class="bg-light rounded h-100 px-3 pt-3 mx-n3">

        <div class="row align-items-center mb-2">
          <div class="col-6" [ngClass]="IsAdjusterView ? 'col-md-2' : ''">
            <button *ngIf="!claim!.isLocked" type="button" class="btn btn-outline-danger btn-bg-white rounded-2" (click)="openDeleteRepairModal()" ngbTooltip="Delete Repair Item">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                   fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-trash">
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              </svg>
              Remove
            </button>
          </div>
          <div class="col-6" [ngClass]="IsAdjusterView ? 'col-md-2 order-md-last' : ''">
            <div class="text-end text-muted lh-md">REPAIR COST</div>
            <div class="h3 text-dark text-end text-nowrap mb-0">{{repair.total | currency}}</div>
          </div>
          <ng-container *ngIf="IsAdjusterView">
            <div class="col-12 col-md-8 mt-3 mt-md-0 text-center">
              <div class="btn-group" role="group">
                <button *ngIf="!claim!.isLocked" class="btn btn-outline-success btn-bg-white px-3" type="button" ngbTooltip="Accept Repair Item" (click)="approveRepairItem()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                       fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                       class="feather feather-check">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                  Accept Repair Item
                </button>
                <button *ngIf="!claim!.isLocked" class="btn btn-outline-danger btn-bg-white px-3" type="button" ngbTooltip="Reject Repair Item" (click)="denyRepairItem()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                       fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                       class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                  Reject Repair Item
                </button>
              </div>
            </div>
          </ng-container>
        </div>
        <hr class="mb-3 mt-0">

        <div class="row">

          <div class="col-sm-12 col-lg-4 d-flex flex-column">
            <fieldset class="mb-3 flex-grow-1 d-flex flex-column" [ngClass]="{'mt-md-n2': !claim?.isSubmitted}">
              <label class="form-label text-nowrap mb-0">Description <span class="required" *ngIf="repair.isPartTypeNotListed">*</span></label>
              <small *ngIf="!claim?.isSubmitted" class="text-info">Please include part numbers for review.</small>
              <textarea class="form-control flex-grow-1" [readOnly]="claim!.isLocked" [(ngModel)]="repair.description"
                        (keyup)="saveRepairDebounce()" [required]="repair.isPartTypeNotListed" rows="5"></textarea>
              <div class="invalid-feedback lh-sm">Please provide a description.</div>
            </fieldset>
          </div>

          <div class="col-lg-8 mt-auto">
            <div class="row">
              <fieldset class="col-sm-12 col-md-6 mb-3"  [ngClass]="{'adjusted': repair.partId != RepairAtTimeOfSubmission?.partId}">
                <label class="form-label text-nowrap mb-0">Part Type <span class="required">*</span></label>
                <ng-container *ngIf="availablePartGroups.length">
                  <select class="form-select" [disabled]="claim!.isLocked || repair.isPartTypeNotListed" required [(ngModel)]="repair.partId" (change)="onPartTypeChange();">
                    <option value=null selected>[Select...]</option>
                    <ng-container *ngFor="let partGroup of availablePartGroups">
                      <optgroup label="{{partGroup.label}}">
                        <ng-container *ngIf="availablePartGroups.length">
                          <ng-container *ngFor="let part of partGroup.parts">
                            <option [value]="part.partId">{{part.label}}</option>
                          </ng-container>
                        </ng-container>
                      </optgroup>
                    </ng-container>
                  </select>
                </ng-container>
                <app-spinner *ngIf="!availablePartGroups.length"></app-spinner>
                <label class="form-check mt-1 mb-0">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="repair.isPartTypeNotListed" (change)="onPartTypeChange();">
                  <span class="form-check-label">Part type not listed</span>
                </label>
                <div class="invalid-feedback lh-sm" >Please select the part type.</div>
              </fieldset>
              <fieldset class="col-6 col-md-3 order-1 mb-3" [ngClass]="{'adjusted': repair.partQuantity != RepairAtTimeOfSubmission?.partQuantity}">
                <label class="form-label text-nowrap mb-0">Part Quantity <span class="required">*</span></label>
                <div class="input-group">
                  <input class="form-control" type="number" [readOnly]="claim!.isLocked" [(ngModel)]="repair.partQuantity"
                         (input)="saveRepairDebounce();calculateTotal();">
                </div>
                <div class="invalid-feedback lh-sm">Please provide the quantity.</div>
              </fieldset>
              <fieldset class="col-6 col-md-3 order-2 mb-3" [ngClass]="{'adjusted': repair.partPrice != RepairAtTimeOfSubmission?.partPrice}">
                <label class="form-label text-nowrap mb-0">Cost Per Part <span class="required">*</span></label>
                <div class="input-group">
                  <span class="input-group-text">$</span>
                  <input class="form-control" type="number" [readOnly]="claim!.isLocked" [(ngModel)]="repair.partPrice"
                         (input)="saveRepairDebounce();calculateTotal();">
                </div>
                <div class="invalid-feedback lh-sm">Please provide the cost.</div>
              </fieldset>
              <ng-container *ngIf="repair.isPartTypeNotListed">
                <fieldset class="col-sm-12 col-md-6 order-md-3 mb-3" [ngClass]="{'adjusted': false}">
                  <label class="form-label text-nowrap mb-0">Part Description <span class="required">*</span></label>
                  <input class="form-control" type="text" maxlength="25" [readOnly]="claim!.isLocked" [(ngModel)]="repair.partDescription"
                       (input)="saveRepairDebounce();">
                </fieldset>
              </ng-container>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6 mb-3">
                <div class="row">
                  <fieldset class="col-6" [ngClass]="{'adjusted': repair.labourHours != RepairAtTimeOfSubmission?.labourHours}">
                    <label class="form-label text-nowrap mb-0">Labour Hours <span class="required">*</span></label>
                    <input 
                      class="form-control"
                      type="number"
                      required
                      [readOnly]="claim!.isLocked"
                      [(ngModel)]="repair.labourHours"
                      (input)="saveRepairDebounce();updateLaborCharge();calculateTotal();updateLaborRateText();"
                    >
                    <div class="invalid-feedback lh-sm">Please provide the labour in hours.</div>
                  </fieldset>
                  <fieldset class="col-6" [ngClass]="{'adjusted': repair.labourPrice != RepairAtTimeOfSubmission?.labourPrice}">
                    <label class="form-label text-nowrap mb-0">Labour Charge <span class="required">*</span></label>
                    <div class="input-group">
                      <span class="input-group-text">$</span>
                      <input class="form-control" [readOnly]="claim!.isLocked" [(ngModel)]="repair.labourPrice" type="number" required
                             (input)="saveRepairDebounce();calculateTotal();updateLaborRateText();">
                    </div>
                    <div class="invalid-feedback lh-sm">Please provide the labour charge.</div>
                  </fieldset>
                  <div class="col-12 mt-1"><div class="bg-gray-200 rounded text-center lh-1 py-1">{{laborRate || '&nbsp;'}}</div></div>
                </div>
              </div>
              <fieldset class="col-6 col-md-3 mb-3" [ngClass]="{'adjusted': repair.subletPrice != RepairAtTimeOfSubmission?.subletPrice}">
                <label class="form-label text-nowrap mb-0">Sublet</label>
                <div class="input-group">
                  <span class="input-group-text">$</span>
                  <input class="form-control" [readOnly]="claim!.isLocked" type="number" [(ngModel)]="repair.subletPrice"
                         (input)="saveRepairDebounce();calculateTotal();">
                </div>
              </fieldset>
              <fieldset class="col-6 col-md-3 mb-3" [ngClass]="{'adjusted': repair.otherPrice != RepairAtTimeOfSubmission?.otherPrice}">
                <label class="form-label text-nowrap mb-0">Other</label>
                <div class="input-group">
                  <span class="input-group-text">$</span>
                  <input class="form-control" type="number" [readOnly]="claim!.isLocked" [(ngModel)]="repair.otherPrice"
                         (input)="saveRepairDebounce();calculateTotal();">
                </div>
              </fieldset>
            </div>
          </div>

          <div *ngIf="claim?.isSubmitted && RepairAtTimeOfSubmission">
            <hr class="mt-0 mb-1">
            <div class="d-md-none text-muted mb-2">ORIGINAL VALUES</div>
            <div class="row row-cols-2 row-cols-sm-4 row-cols-lg-auto justify-content-lg-between">
              <div class="col d-none d-md-block lh-md mb-3">
                <label class="d-block text-muted mb-0">ORIGINAL<br>VALUES</label>
              </div>
              <div class="col-sm-6 col-lg lh-md mb-3" [ngClass]="{'changed': false}">
                <label class="d-block form-label text-nowrap mb-0">Part Type</label>
                <span class="text-dark">{{repair.partDescription}}</span>
              </div>
              <div class="col lh-md mb-3" [ngClass]="{'changed': RepairAtTimeOfSubmission.partQuantity !== repair.partQuantity}">
                <label class="d-block form-label text-nowrap mb-0">Part Quantity</label>
                <span class="text-dark">{{RepairAtTimeOfSubmission.partQuantity || '0'}}</span>
              </div>
              <div class="col lh-md mb-3" [ngClass]="{'changed': RepairAtTimeOfSubmission.partPrice !== repair.partPrice}">
                <label class="d-block form-label text-nowrap mb-0">Cost Per Part</label>
                <span class="text-dark">{{RepairAtTimeOfSubmission.partPrice || '0' | currency}}</span>
              </div>
              <div class="col lh-md mb-3" [ngClass]="{'changed': RepairAtTimeOfSubmission.labourHours !== repair.labourHours}">
                <label class="d-block form-label text-nowrap mb-0">Labour Hours</label>
                <span class="text-dark">{{RepairAtTimeOfSubmission.labourHours || '0'}}</span>
              </div>
              <div class="col lh-md mb-3" [ngClass]="{'changed': RepairAtTimeOfSubmission.labourPrice !== repair.labourPrice}">
                <label class="d-block form-label text-nowrap mb-0">Labour Charge</label>
                <span class="text-dark">{{RepairAtTimeOfSubmission.labourPrice || '0' | currency}}</span>
              </div>
              <div class="col lh-md mb-3" [ngClass]="{'changed': RepairAtTimeOfSubmission.subletPrice !== repair.subletPrice}">
                <label class="d-block form-label text-nowrap mb-0">Sublet</label>
                <span class="text-dark">{{RepairAtTimeOfSubmission.subletPrice || '0' | currency}}</span>
              </div>
              <div class="col lh-md mb-3" [ngClass]="{'changed': RepairAtTimeOfSubmission.otherPrice !== repair.otherPrice}">
                <label class="d-block form-label text-nowrap mb-0">Other</label>
                <span class="text-dark">{{RepairAtTimeOfSubmission.otherPrice || '0' | currency}}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div *ngIf="claim?.isSubmitted" class="col-xl-3 px-3 mb-2">
      <div *ngIf="!IsAdjusterView" class="d-flex align-items-center bg-gray-100 rounded px-3 pt-3 mx-n3 h-100">
        <div *ngIf="repair.status === 'Approved'" class="text-center w-100">
          <svg class="text-success" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
          <div class="text-success fs-4 fw-bolder lh-sm mt-1">APPROVED!</div>
          <p class="mt-2">Please proceed with the repair.</p>
        </div>
        <div *ngIf="repair.status === 'Denied'" class="text-center w-100">
          <svg class="text-danger" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
          <div class="text-danger fs-4 fw-bolder lh-sm mt-1">NOT APPROVED</div>
          <p class="mt-2">Global will not reimburse this repair.</p>
        </div>
        <div *ngIf="repair.status === 'Unadjudged' && repair.preApprovalAmount === repair.total && repair.preApprovalAmount" class="text-center w-100">
          <svg class="text-success" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
          <div class="text-success fs-4 fw-bolder lh-sm mt-1">PRE-APPROVED!</div>
          <p class="mt-2">Please proceed with the repair.</p>
        </div>
        <div *ngIf="repair.status === 'Unadjudged' && repair.preApprovalAmount! > 0 && repair.preApprovalAmount !== repair.total" class="text-center w-100">
          <svg class="text-black-50" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
          <div class="text-black-50 fs-4 fw-bolder lh-sm mt-1">PARTIALLY <span class="text-nowrap">PRE-APPROVED FOR</span></div>
          <div class="h3 text-dark mt-1 mb-0">{{repair.preApprovalAmount! | currency}}</div>
        </div>
        <div *ngIf="repair.status === 'Unadjudged' && !repair.preApprovalAmount" class="text-center w-100">
          <svg class="text-warning" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
          <div class="text-warning fs-4 fw-bolder lh-sm mt-1">PENDING APPROVAL</div>
          <p class="mt-2">Please wait on repair work until confirmation from Global.</p>
        </div>
      </div>

      <div *ngIf="IsAdjusterView" class="bg-gray-100 rounded p-3 mx-n3 h-100">
        <div class="row justify-content-center">
          <div class="col-sm-6 col-md-4 col-xl-12">
            <div *ngIf="repair.status === 'Approved'" class="text-center">
              <svg class="d-inline-block text-success me-2" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
              <div class="d-inline-block text-success fw-bolder mt-1">APPROVED!</div>
            </div>
            <div *ngIf="repair.status === 'Denied'" class="text-center">
              <svg class="d-inline-block text-danger me-2" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
              <div class="d-inline-block text-danger fw-bolder mt-1">NOT APPROVED</div>
            </div>
            <div *ngIf="repair.status === 'Unadjudged' && repair.preApprovalAmount! !== 0 && repair.preApprovalAmount === repair.total" class="text-center">
              <svg class="d-inline-block text-success me-2" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
              <div class="d-inline-block text-success fw-bolder mt-1">PRE-APPROVED!</div>
            </div>
            <div *ngIf="repair.status === 'Unadjudged' && repair.preApprovalAmount! > 0 && repair.preApprovalAmount !== repair.total" class="text-center">
              <svg class="d-inline-block text-info me-2" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
              <div class="d-inline-block text-info fw-bolder">PARTIALLY PRE-APPROVED FOR</div>
              <div class="h3 text-dark mt-n2">{{repair.preApprovalAmount! | currency}}</div>
            </div>
            <div *ngIf="repair.status === 'Unadjudged' && !repair.preApprovalAmount" class="text-center">
              <svg class="d-inline-block text-warning me-2" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
              <div class="d-inline-block text-warning fw-bolder mt-1">PENDING APPROVAL</div>
            </div>
            
            <ng-container *ngIf="IsAdjusterView && part && !repair.isPartTypeNotListed">
              <p class="mt-3 mb-0 mx-1 fw-bold">Part Failure Probability <span class="float-end"><ng-container *ngIf="part.claimProbability">{{part.claimProbability | percent}}</ng-container><ng-container *ngIf="!part.claimProbability">Unknown</ng-container></span></p>
              <div class="progress progress-sm mx-1">
                <div class="progress-bar bg-info" role="progressbar" [style.width]="part.claimProbability ?? 0 | percent">
                </div>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="preApprovalExplanation.length > 0">
            <div class="col-sm-6 col-md-4 col-xl-12 mt-3 mt-sm-0 mt-xl-3">
              <table class="table table-sm table-no-bottom-border table-explanation lh-md mb-0">
                <tbody>
                <ng-container *ngFor="let item of preApprovalExplanation">
                  <tr *ngIf="item.label">
                    <td>{{item.label}}</td>
                    <td class="text-start">{{item.quantity}}</td>
                    <td *ngIf="item.label !== 'Match on'" class="text-end">{{item.value}}</td>
                    <td *ngIf="item.label == 'Match on'" class="text-end">
                      <span *ngIf="item.value == 'M'" class="text-nowrap">Make</span>
                      <span *ngIf="item.value == 'MM'" class="text-nowrap">Model</span>
                      <span *ngIf="item.value == 'MMT'" class="text-nowrap">Trim</span>
                      <span *ngIf="item.value !== 'M' && item.value !== 'MM' && item.value !== 'MMT'"
                            class="text-nowrap">{{item.value}}</span>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
      </div>

    </div>
  </div>

</ng-container>
