<div class="row">
  <div class="col-sm-12" [ngClass]="{'col-xl-9 px-3': isAdjusterView}">
    <hr class="mb-1 mt-0 text-black">
    <div class="mb-4">
      <span class="h4">Customer</span>
      <button class="btn btn-link btn-collapse float-end p-0" type="button" data-bs-toggle="collapse" data-bs-target="#customerExpandCollapse" aria-expanded="true" aria-controls="customerExpandCollapse">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg>
      </button>
      <app-spinner [show]="!customerInformationLoaded" [center]="false"></app-spinner>
    </div>
  </div>
  <div *ngIf="isAdjusterView" class="col-sm-12 col-xl-3 px-3 d-none d-xl-block">
    <div class="bg-light d-flex justify-content-between align-items-center rounded lh-md h-100 px-3 py-2 mx-n3">
        <div class="fw-bold">Customer ID</div>
        <div>
          <a *ngIf="customerOnFile?.customerId" target="_blank" class="fw-bolder" href="https://e2.eazeetrak.com/customer/{{customerOnFile?.customerId}}">
            {{customerOnFile?.customerId}}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link ms-1"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
          </a>
        </div>
    </div>
  </div>
</div>
<div id="customerExpandCollapse" class="row collapse show">
  <div class="col-sm-12" [ngClass]="{'col-xl-9 px-3': isAdjusterView}">
    <div class="collapse show mb-4">
      <div class="row">
        <div class="col-xl-6">
          <div class="row">
            <fieldset class="col-md-6 mb-3">
              <label class="form-label text-nowrap mb-0">First Name</label>
              <input
                [readOnly]="isLocked"
                class="form-control"
                type="text"
                [disabled]="!customerInformationLoaded"
                (keyup)="saveCustomerInformationDebounce()"
                [(ngModel)]="claimVerifiedCustomerInformationDto!.firstName"
                autocomplete="off"
              >
            </fieldset>
            <fieldset class="col-md-6 mb-3">
              <label class="form-label text-nowrap mb-0">Last Name</label>
              <input
                [readOnly]="isLocked"
                class="form-control"
                type="text"
                [disabled]="!customerInformationLoaded"
                (keyup)="saveCustomerInformationDebounce()"
                [(ngModel)]="claimVerifiedCustomerInformationDto!.lastName"
                autocomplete="off"
              >
              <div class="invalid-feedback lh-sm">Please provide a name.</div>
            </fieldset>
            <fieldset class="col-md-6 col-xl-12 mb-3">
              <label class="form-label text-nowrap mb-0">Email Address</label>
              <input
                [readOnly]="isLocked"
                class="form-control"
                type="text"
                [disabled]="!customerInformationLoaded"
                (keyup)="saveCustomerInformationDebounce()"
                [(ngModel)]="claimVerifiedCustomerInformationDto!.email"
                autocomplete="off"
              >
            </fieldset>
            <fieldset class="col-md-6 mb-3">
              <label class="form-label text-nowrap mb-0">Primary Phone</label>
              <input
                [readOnly]="isLocked"
                class="form-control"
                type="text"
                [disabled]="!customerInformationLoaded"
                (keyup)="saveCustomerInformationDebounce()"
                mask="(000) 000-0000"
                [(ngModel)]="claimVerifiedCustomerInformationDto!.phone"
                autocomplete="off"
              >
            </fieldset>
          </div>
        </div>
        <div class="col-xl-6 mt-4 mt-xl-0">
          <div class="row">
            <fieldset class="col-12 mb-3">
              <label class="form-label text-nowrap mb-0">Address</label>
              <input
                [readOnly]="isLocked" class="form-control"
                type="text"
                
                [disabled]="!customerInformationLoaded"
                (keyup)="saveCustomerInformationDebounce()"
                [(ngModel)]="claimVerifiedCustomerInformationDto!.address"
                autocomplete="off"
              >
              <div class="invalid-feedback lh-sm">Please provide an address.</div>
            </fieldset>
            <fieldset class="col-md-6 mb-3">
              <label class="form-label text-nowrap mb-0">City</label>
              <input
                [readOnly]="isLocked"
                class="form-control"
                type="text"
                [disabled]="!customerInformationLoaded"
                (keyup)="saveCustomerInformationDebounce()"
                [(ngModel)]="claimVerifiedCustomerInformationDto!.city"
                autocomplete="off"
              >
              <div class="invalid-feedback lh-sm">Please provide a city.</div>
            </fieldset>
            <fieldset class="col-md-6 mb-3">
              <label class="form-label text-nowrap mb-0">Province / State</label>
              <select
                [disabled]="isLocked"
                class="form-select"
                [(ngModel)]="claimVerifiedCustomerInformationDto.provinceStateId"
                (change)="saveCustomerInformation()"
                autocomplete="off"
              >
                <option value=0 selected>[Select...]</option>
                <ng-container *ngFor="let stateProvince of statesProvinces">
                  <option [value]="stateProvince.ProvinceStateId">{{stateProvince.Label}}</option>
                </ng-container>
              </select>
              <div class="invalid-feedback lh-sm">Please select a province or a state.</div>
            </fieldset>
            <fieldset class="col-md-6 mb-3">
              <label class="form-label text-nowrap mb-0">Postal / Zip Code</label>
              <input
                [readOnly]="isLocked"
                class="form-control"
                type="text"
                [disabled]="!customerInformationLoaded"
                (keyup)="saveCustomerInformationDebounce()"
                [(ngModel)]="claimVerifiedCustomerInformationDto!.postalZipCode"
                autocomplete="off"
              >
              <div class="invalid-feedback lh-sm">Please provide a postal or zip code.</div>
            </fieldset>
            <fieldset class="col-md-6 mb-3">
              <label class="form-label text-nowrap mb-0">Country</label>
              <select
                [disabled]="isLocked"
                class="form-select"
                [(ngModel)]="claimVerifiedCustomerInformationDto.countryId"
                (change)="saveCustomerInformation()"
              >
                <option value=1>Canada</option>
                <option value=2>United States</option>
              </select>
              <div class="invalid-feedback lh-sm">Please select a province or a state.</div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isAdjusterView" class="col-xl-3 px-3 mt-n3 mt-xl-0 mb-4 mb-xl-0">
    <div class="bg-gray-100 rounded lh-md px-3 mx-n3 h-100 py-3 mb-3 mb-xl-0">
      <div class="row">
        <div class="col-sm-6 col-xl-12">
          <div *ngIf="isMatch" class="alert alert-success mb-2">
            <div class="bg-success text-success rounded-start bg-opacity-25 py-1 px-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="check" class="feather feather-check"><path d="M20 6 9 17l-5-5"></path></svg>
            </div>
            <div class="d-flex align-items-center text-body fw-bold px-3"><div>Appears to match</div></div>
          </div>
          <div *ngIf="!isMatch" class="alert alert-warning mb-2">
            <div class="bg-warning text-warning rounded-start bg-opacity-25 py-1 px-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="alert-triangle" class="feather-alert-triangle"><path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3"></path><path d="M12 9v4"></path><path d="M12 17h.01"></path></svg>
            </div>
            <div class="d-flex align-items-center text-body fw-bold px-3"><div>Customer record may not match</div></div>
          </div>
          <div class="d-flex d-xl-none justify-content-between bg-light rounded px-3 py-1 mt-3">
            <div class="fw-bold">Customer ID</div>
            <div>
              <a *ngIf="customerOnFile?.customerId" target="_blank" class="fw-bolder" href="https://e2.eazeetrak.com/customer/{{customerOnFile?.customerId}}">
                {{customerOnFile?.customerId}}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link ms-1"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
              </a>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-xl-12">
          <table class="table table-sm table-no-bottom-border table-explanation mb-0">
            <tbody>
            <tr>
              <td>First Name</td>
              <td>{{customerOnFile?.firstName}}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{{customerOnFile?.lastName}}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>{{customerOnFile?.address}}</td>
            </tr>
            <tr>
              <td>City</td>
              <td>{{customerOnFile?.city}}</td>
            </tr>
            <tr>
              <td>Province / State</td>
              <td>{{customerOnFile?.province}}</td>
            </tr>
            <tr>
              <td>Postal / Zip Code</td>
              <td>{{formatPostalZipCode(customerOnFile?.postalZipCode)}}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td><span *ngIf="customerOnFile?.email"><a href="mailto://{{customerOnFile?.email}}">{{customerOnFile?.email?.toLowerCase()}}</a></span></td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>{{formatPhoneNumber(customerOnFile?.phone)}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
