<div class="modal-header">
  <h4 class="modal-title ms-3">Delete Claim</h4>
  <button type="button" class="btn-close me-2" data-bs-dismiss="modal" aria-label="Close" (click)="this.activeModal.close()"></button>
</div>

<ng-container *ngIf="showSuccess">
  <div class="modal-body mx-3">
    <div class="bg-light rounded p-3 mb-3">
      <div class="text-center pt-3">
        <svg class="text-danger" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"
             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <polyline points="3 6 5 6 21 6"></polyline>
          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
        </svg>
        <div class="text-danger fs-4 fw-bolder lh-sm mt-1">DELETED!</div>
        <p class="mt-3 fw-bolder text-dark">Claim {{claimDto.claimId}} has been deleted.</p>
        <button class="btn btn-primary my-3" (click)="returnToClaimsList()">
          <svg 
              xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" 
              class="feather feather-corner-down-left me-2">
            <polyline points="9 10 4 15 9 20"></polyline>
            <path d="M20 4v7a4 4 0 0 1-4 4H4"></path>
          </svg>
          Back to Claims List
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!showSuccess">
  <div class="modal-body mx-3">
    <p class="lh-md"><strong>Do you wish to delete this claim?</strong></p>
    <p class="lh-md">This claim will no longer be visible to the repair center. Or, you can instead mark this claim as a test.</p>
    <table class="table table-sm table-no-bottom-border">
      <tbody>
      <tr><th class="ps-0">Claim ID</th><td>{{claimDto.claimId}}</td></tr>
      <tr><th class="ps-0">Repair Center</th><td>{{claimDto.repairCentre.name}}</td></tr>
      <tr><th class="ps-0">Customer</th><td>{{claimDto.customerOnFile.firstName}} {{claimDto.customerOnFile.firstName}}</td></tr>
      <tr><th class="ps-0">Vehicle</th><td>{{claimDto.vehicle.year}} {{claimDto.vehicle.make}} {{claimDto.vehicle.model}}</td></tr>
      <tr><th class="ps-0">VIN</th><td>{{claimDto.vehicle.vin}}</td></tr>
      <tr><th class="ps-0">Claim Type</th><td>{{claimDto.coverageTypeGroupLabel}}</td></tr>
      </tbody>
    </table>
  </div>
      
  <div class="modal-footer">
    <div class="px-3">
      <button [disabled]="isBusyDeleting || isBusyTestData" class="btn btn-outline-secondary me-3" (click)="this.activeModal.close()">Cancel</button>
      <button [disabled]="isBusyDeleting || isBusyTestData" class="btn btn-secondary position-relative me-3" (click)="markTestData()">
        <span
            class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto"
            role="status"
            aria-hidden="true"
            [ngClass]="{'show': isBusyTestData}">
        </span>
        <span class="text fade show" [ngClass]="{'show': !isBusyTestData}">
          Mark as Test Data
        </span>
      </button>
      <button [disabled]="isBusyDeleting || isBusyTestData" class="btn btn-danger position-relative" (click)="adjusterDeleteClaim()">
        <span
            class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto"
            role="status"
            aria-hidden="true"
            [ngClass]="{'show': isBusyDeleting}">
        </span>
        <span class="text fade show" [ngClass]="{'show': !isBusyDeleting}">
          <svg
              xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-trash">
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
          </svg>
          Delete Claim
        </span>
      </button>
  </div>
  </div>
</ng-container>



  
  
    