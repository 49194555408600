<app-spinner [show]="loading" [center]="true"></app-spinner>

<ng-container *ngIf="!loading" class="d-flex justify-content-center">

  <div #stickyTotals>
    <app-sticky-totals *ngIf="claimDto" [manuallyEnteredDeductible]="claimDto.manuallyEnteredDeductible" [claimDto]="claimDto" [repairTotal]="repairTotal"></app-sticky-totals>
  </div>

  <h1 class="h3 mb-3"><a class="link-primary" href="/claim-list">Your Claims</a> > {{claimDto?.coverageTypeGroupLabel}} Claim</h1>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header bg-primary-dark text-light d-block d-md-flex">
          <app-vehicle-information [claim]="claimDto" class="d-block d-xl-flex"></app-vehicle-information>
          <div class="border-start border-white-35 d-none d-md-flex ps-md-4"></div>
            <app-warranty-information
              [warranty]="claimDto!.warranty"
              class="pt-3 pt-1 pt-md-1">
            </app-warranty-information>
        </div>
        <div class="card-body p-4">
          <ng-container *ngIf="claimDto">

            <div class="row mt-3">
              <div class="col-sm-6 col-md-8 col-xl-9 px-3">
                <div class="alert alert-danger alert-outline-coloured mb-5" *ngIf="claimExpired">
                  <div class="alert-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                      <line x1="12" y1="8" x2="12" y2="12"></line>
                      <line x1="12" y1="16" x2="12.01" y2="16"></line>
                    </svg>
                  </div>
                  <div class="alert-message">
                    <strong>This warranty has expired.</strong><br>
                    You may still submit a claim towards this policy, but items listed will not be pre-approved, and
                    will require review by an adjuster before any approvals could be considered. The customer should be
                    aware of their expired warranty and that approval is not likely, before you conduct any repairs.
                  </div>
                </div>

                <app-repair-centre-duplicate-claims [claimId]="claimDto.claimId"></app-repair-centre-duplicate-claims>
                <app-messages *ngIf="claimDto!.isLocked" [claimDto]="claimDto"></app-messages>
                <app-customer-information [applicationId]="claimDto.applicationId"></app-customer-information>
              </div>
              <div class="price-totals-container repair-center col-sm-6 col-md-4 col-xl-3 mb-4 px-3">
                <div class="row">
                  <div class="col-12">
                    <div class="border border-3 border-white rounded">
                      <div class="bg-gray-200 px-4 py-3 text-center">
                        <label class="form-label d-block text-uppercase mb-1">Claim Status</label>
                        <app-status-badge [claimDto]="claimDto"></app-status-badge>
                        <ng-container *ngIf="claimAuthorized">
                          <label class="form-label d-block text-uppercase mt-3 mb-0">Authorization Number</label>
                          <div class="fs-2 fw-bold lh-sm">{{claimDto.authorizationNumber}}</div>
                        </ng-container>
                      </div>
                      <div #priceTotals class="bg-light px-4 pt-4 pb-3">
                        <app-totals [claimDto]="claimDto!" [repairTotal]="repairTotal"></app-totals>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <app-repairs
              [Submitted]="submitted"
              [ClaimDto]="claimDto"
              [IsAdjusterView]="false"
              (totalUpdated)="setRepairTotal($event)"
              (repairsUpdated)="updateRepairs($event)"
            ></app-repairs>

            <div class="row">
              <div class="col-xl-9">
                <app-file-upload [ClaimDto]="claimDto"></app-file-upload>

                <app-initial-message-from-repair-centre-to-global *ngIf="!claimDto.isSubmitted" [claimDto]="claimDto"></app-initial-message-from-repair-centre-to-global>
                <hr class="mt-4 text-black">
                <div class="text-end">
                  <button class="btn btn-lg btn-outline-secondary position-relative me-3" type="button"
                          [disabled]="!repairTotal || isLocked">
                    <span
                      class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto"
                      role="status" aria-hidden="true"></span>
                    <span class="text fade show">Save as Draft</span>
                  </button>
                  <button class="btn btn-lg btn-primary position-relative" type="button" (click)="submitClaim()"
                          [disabled]="!repairTotal || isLocked">
                    <span
                      class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto"
                      role="status" aria-hidden="true" [ngClass]="{'show': busySubmitting}"></span>
                    <span class="text fade show" [ngClass]="{'show': !busySubmitting}">Submit Claim</span>
                  </button>
                </div>

              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!environment.production">
    <hr>
    <h3>Debugging</h3>
    <button class="btn btn-primary" (click)="openClaimSubmitModal()">Show Submit modal</button>
    <button class="btn btn-primary" (click)="submitClaim()">Submit</button>
    <button class="btn btn-primary" (click)="isLocked = false">Unlock</button>
  </div>
</ng-container>
