<div class="modal-header">
    <h4 class="modal-title ms-3">{{modalTitle}}</h4>
    <button type="button" class="btn-close me-2" data-bs-dismiss="modal" aria-label="Close" (click)="closeSearchModal()"></button>
</div>

<div class="modal-body py-4">
    <div class="container-fluid px-3">
        <div class="row mb-4">
            <fieldset class="col-sm-8 offset-sm-2">
                <label class="form-label mb-1">{{searchLabel}}<span class="form-required ps-1">*</span></label>
                <input type="text" id="searchField" class="form-control" (keyup)="doSearch($event)" autocomplete="off">
            </fieldset>
        </div>
        <div *ngIf="vendorSearchResults && vendorSearchResults.length > 0" class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table table-sm table-striped table-hover lh-md">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>Province / State</th>
                            <th>Postal / Zip Code</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let searchResult of vendorSearchResults">
                            <td>{{searchResult.vendorId}}</td>
                            <td>{{searchResult.friendlyName !== null ? searchResult.friendlyName : searchResult.legalName}}</td>
                            <td>{{searchResult.address}}</td>
                            <td>{{searchResult.city}}</td>
                            <td>{{searchResult.province}}</td>
                            <td>{{Utils.formatPostalZipCode(searchResult.postalZipCode)}}</td>
                            <td class="text-end text-nowrap py-1">
                                <button 
                                    class="btn btn-outline-primary btn-sm position-relative"
                                    type="button"
                                    (click)="selectVendor(searchResult)"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right me-1"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                    Select
                                </button>
                            </td>
                        </tr>                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="repairCentreSearchResults && repairCentreSearchResults.length > 0" class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table table-sm table-striped table-hover lh-md">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>Province / State</th>
                            <th>Postal / Zip Code</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let searchResult of repairCentreSearchResults">
                            <td>{{searchResult.repairCentreId}}</td>
                            <td>{{searchResult.friendlyName != null ? searchResult.friendlyName : searchResult.name}}</td>
                            <td>{{searchResult.address}}</td>
                            <td>{{searchResult.city}}</td>
                            <td>{{searchResult.province}}</td>
                            <td>{{Utils.formatPostalZipCode(searchResult.postalCode)}}</td>
                            <td class="text-end text-nowrap py-1">
                                <button
                                    class="btn btn-outline-primary btn-sm position-relative"
                                    type="button"
                                    (click)="selectRepairCentre(searchResult)"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right me-1"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                    Select
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>        
    </div>
</div>
