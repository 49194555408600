import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RejectService {

  constructor(private httpClient: HttpClient) {
  }

  reject(claimId: number, adjusterFinalMessage: string) {
    return this.httpClient.put(`${environment.apiServer}/Adjuster/Claim/Reject`, {claimId, adjusterFinalMessage});
  }
}
